import React from 'react';
import '../styles/team.scss';

const CoreMember = ({ name, tagline, photo }) => (
    <div
        className="coreMember"
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
    >
        <img
            src={photo}
            alt={`Team member ${name}`}
            style={{
                width: '200px',
                borderRadius: 100,
            }}
        />
        <div
            style={{
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontWeight: 'bold',
                fontSize: '18px',
                lineHeight: '140%',
                letterSpacing: '0.03em',
                paddingBottom: '12px',
            }}
        >
            {name}
        </div>
        <div
            style={{
                textAlign: 'center',
            }}
        >
            {tagline || 'Team member at Earth Hero'}
        </div>
    </div>
);

export default CoreMember;
