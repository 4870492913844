import React from 'react';
import { motion } from 'framer-motion';

import Layout from '../components/layout';
import SEO from '../components/seo';
import CoreMember from '../components/CoreMember';

import '../styles/global.scss';
import '../styles/team.scss';

const TeamTemplate = ({ pageContext: { heroes } }) => (
    <Layout>
        <SEO title="Team" />
        <section className="team section">
            <div className="container pageHero">
                <div className="textBlock">
                    <motion.h1
                        initial={{
                            opacity: 0,
                            scale: 0.8,
                            y: 20,
                        }}
                        animate={{
                            scale: 1,
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{
                            duration: 1,
                        }}
                        className="pageHeadline"
                    >
                        We are Earth Heroes
                    </motion.h1>

                    <motion.div
                        initial={{
                            opacity: 0,
                            scale: 0.8,
                            y: 20,
                        }}
                        animate={{
                            scale: 1,
                            opacity: 1,
                            y: 0,
                        }}
                        transition={{
                            duration: 1,
                            delay: 0.4,
                        }}
                        className="pageHeroSubhead"
                    >
                        We believe a billion compassionate activists can restore a healthy earth
                        where life can thrive, so we are motivated to inspire people and their
                        communities to join the global climate movement.
                        <br />
                        <br />
                        Our nonprofit volunteer team is focused on providing tools and ideas that
                        empower all who want to reduce emissions and care for our shared planet. We
                        help people to effectively respond to the climate emergency while
                        strengthening their resilience and quality of life.
                    </motion.div>
                </div>
            </div>
        </section>

        <div className="coreTeam">
            {heroes.map((hero, i) => {
                const { name, tagline, photoUrl } = hero;
                return <CoreMember key={i} name={name} tagline={tagline} photo={photoUrl} />;
            })}
        </div>
    </Layout>
);

export default TeamTemplate;
